import React from 'react';
// import logo from './logo.svg';
import arcade from './arcade-cabinets.svg';
import iconWordNerd from './icon-wordnerd.svg';
import iconWordplay from './icon-wordswithjoe.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='heading'>
          Games
        </div>
        <div className='heading-tagline'>
          at <a href='http://joehall.net/'>JoeHall.net</a>
        </div>
        <img src={arcade} className="arcade" alt="logo" />
        <div className='tag-line'>
          More coming soon.
        </div>
        <div className='icons-wrapper'>
          <div className='icons-content'>
            <a href='/wordnerd'><img src={iconWordNerd} className='game-icon' alt='Word Nerd game' /></a>
            <a href='/wordswithjoe'><img src={iconWordplay} className='game-icon' alt='Words with Joe game' /></a>
          </div>
        </div>
        {/*<a*/}
        {/*  className="App-link"*/}
        {/*  href="/wordnerd"*/}
        {/*  target="_blank"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*>*/}
        {/*  Word Nerd*/}
        {/*</a>*/}
        {/*<a*/}
        {/*  className="App-link"*/}
        {/*  href="/wordplay"*/}
        {/*  target="_blank"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*>*/}
        {/*  Wordplay*/}
        {/*</a>*/}
      </header>
    </div>
  );
}

export default App;
